<template>
<v-container>
  <v-row>
  <v-col md="8">
    <h3 class="gold--text">
      Featured Horse

    </h3>
    <v-card class="mt-2">
      <v-card-text>

        <v-row>
          <v-col cols="6">
            <YoutubeVue
            ref="youtube1"
            height="96%"
            width="100%"
            autoplay="1"
            :muted="1"
            :videoid="play.video_id"
            :loop="play.loop"
          />
          </v-col> <v-col cols="6">
            <p>
              Name: Cyranno Z
              <br>
              Date of Birth: 21-04-2018
              <br>
              Mother: ABC
              <br>
              Father: ABD

            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col><v-col md="4">
    <h4 class="gold--text">
      All Horses

    </h4>
      
  </v-col>
 </v-row>
</v-container>

</template>
<script>
import { YoutubeVue } from 'youtube-vue'

export default {
  name: 'Horses',
  components: {
    YoutubeVue
  },
  data(){
    return {
      play: { video_id: "ZhMzyrVOJ-s", loop: 1 },
    }
  }
}
</script>